
import Vue from 'vue';
import { LocIcon, LocButton, LocInput, LocCheckbox } from '@localazy/components';
import getLoginQuery from '@/functions/auth/get-login-query';
import { RawLocation } from 'vue-router';
import SignInButtonsContainer from '@/modules/login/containers/SignInButtonsContainer.vue';
import getServicesStatus from '@/modules/login/functions/get-services-status';
import HcaptchaPlaceholder from '@/modules/login/components/HcaptchaPlaceholder.vue';
import { SessionMode } from '@/modules/login/enums/session-mode';
import HeaderDivider from '@/modules/@common/components/HeaderDivider.vue';

interface IData {
  formData: {
    email: string,
    password: string,
    remember: boolean,
    origin: string,
  };
  isMaintenanceMode: boolean;
}

export default Vue.extend({
  name: 'LoginForm',
  components: {
    LocInput,
    LocCheckbox,
    LocButton,
    SignInButtonsContainer,
    LocIcon,
    HcaptchaPlaceholder,
    HeaderDivider
  },
  props: {
    error: {
      type: String,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data(): IData {
    return {
      formData: {
        email: '',
        password: '',
        remember: true,
        origin: ''
      },
      isMaintenanceMode: false
    };
  },
  computed: {
    registerLink(): RawLocation {
      return { path: '/register', query: getLoginQuery(this.$route) };
    },
    ssoLink(): RawLocation {
      return { path: '/sso', query: getLoginQuery(this.$route) };
    },
    SessionMode(): typeof SessionMode {
      return SessionMode;
    }
  },
  mounted() {
    // Get origin url to redirect after login
    if (this.$route.query.origin) {
      this.formData.origin = this.$route.query.origin.toString();
    }
  },
  created () {
    this.checkIsMaintanenceMode();
  },
  methods: {
    async checkIsMaintanenceMode() {
      const maintenanceMode = await getServicesStatus();
      this.isMaintenanceMode = maintenanceMode;
      this.$nuxt.$emit('on-services-status-fetched', maintenanceMode);
    },
    onSubmit () {
      this.$emit('submit', this.formData);
    },
    onError () {
      this.$emit('error');
    }
  }
});
