
import Vue from 'vue';
import LoginFormContainer from '@/modules/login/containers/LoginFormContainer.vue';
import loadCaptcha from '@/modules/login/functions/load-captcha';
import { redirectIfLoggedIn } from '@/modules/login/functions/redirect-if-logged-in';

const metaTitle = 'Login Page – Localazy';
const metaDescription = 'Login to your Localazy software & content localization and get started with translations in no time.';

export default Vue.extend({
  layout: 'LoginSignupUserFocusLayout',
  components: {
    LoginFormContainer
  },
  async mounted () {
    await loadCaptcha();
    await redirectIfLoggedIn();
  },
  head () {
    return {
      title: metaTitle,
      meta: [
        { hid: 'description', name: 'description', content: metaDescription },
        { hid: 'og:title', property: 'og:title', content: metaTitle },
        { hid: 'og:description', property: 'og:description', content: metaDescription },
        { hid: 'twitter:title', property: 'twitter:title', content: metaTitle },
        { hid: 'twitter:description', property: 'twitter:description', content: metaDescription }
      ]
    };
  }
});
