
import Vue from 'vue';
import LoginForm from '@/modules/login/components/LoginForm.vue';
import login from '@/functions/auth/login';
import parseErrorUrl from '@/functions/login/parse-error-url';
import buildSpaRedirectUrl from '@/modules/@common/functions/build-spa-redirect-url';
import hcaptchaVerifiedRun from '@/modules/login/functions/hcaptcha-verified-run';
import recaptchaVerifiedRun from '@/modules/login/functions/recaptcha-verified-run';

export default Vue.extend({
  name: 'LoginFormContainer',
  components: {
    LoginForm
  },
  data () {
    return {
      errorMessage: '',
      isLoading: false
    };
  },
  mounted () {
    this.errorMessage = this.parseErrorUrl();
  },
  methods: {
    parseErrorUrl ():string {
      return parseErrorUrl(this.$route.query);
    },
    async onSubmit (val:any) {
      this.isLoading = true;
      if (typeof window.hcaptcha !== 'undefined') {
        await this.hcaptchaVerifiedLogin(val);
      } else {
        await this.googleVerifiedLogin(val);
      }
      this.isLoading = false;
    },
    async googleVerifiedLogin(val:any) {
      await recaptchaVerifiedRun('login', val, this.loginUser);
    },
    async hcaptchaVerifiedLogin(val:any) {
      await hcaptchaVerifiedRun(val, this.loginUser);
    },
    async loginUser(val:any, token: string) {
      try {
        const result = await login(val, token);
        if (!result.success) {
          this.errorMessage = result.message;
        } else {
          const queryString = new URLSearchParams(window.location.search);
          window.location.href = buildSpaRedirectUrl(result.redirect, queryString);
        }
      } catch (e: any) {
        this.errorMessage = 'Something went wrong. Try again later.';
      }
    }
  }

});
