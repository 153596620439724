import auth from '@/api/auth';
import IAauthResult from '@/models/auth-result';

export default async (data: { email: string, password: string, remember: boolean, origin?: string }, token: string): Promise<IAauthResult> => {
  try {
    const result = await auth.post('/login',
      {
        type: 'email',
        id: data.email,
        password: data.password,
        persistent: data.remember,
        origin: data.origin || '',
        token
      });
    return result.data;
  } catch (e: any) {
    throw e.data;
  }
};
